import { Controller } from "@hotwired/stimulus"
import { showFlashMessage } from "../utils/flash_message"

export default class extends Controller {
  static targets = ["submitButton", "modal"]

  connect() {
    this.boundHandleKeydown = this.handleKeydown.bind(this)
    document.addEventListener('keydown', this.boundHandleKeydown)
  }

  disconnect() {
    document.removeEventListener('keydown', this.boundHandleKeydown)
  }

  open() {
    this.modalTarget.classList.remove('hidden')
    document.body.classList.add('overflow-hidden')
  }

  close() {
    this.modalTarget.classList.add('hidden')
    document.body.classList.remove('overflow-hidden')
  }

  handleKeydown(event) {
    if (event.key === 'Escape') {
      this.close()
    }
  }

  async submit(event) {
    event.preventDefault()
    const form = event.target
    const submitButton = this.submitButtonTarget
    
    submitButton.disabled = true
    submitButton.innerHTML = `
      <svg class="animate-spin -ml-1 mr-2 h-4 w-4 text-white inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      Submitting...
    `

    try {
      const response = await fetch(form.action, {
        method: form.method,
        body: new FormData(form),
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      })

      if (response.ok) {
        const data = await response.json()
        this.close()
        form.reset()
        showFlashMessage(data.flash.notice, 'notice')
      } else {
        const data = await response.json()
        showFlashMessage(data.flash.alert, 'alert')
      }
    } catch (error) {
      showFlashMessage('An unexpected error occurred. Please try again later.', 'alert')
    } finally {
      submitButton.disabled = false
      submitButton.innerHTML = 'Submit Request'
    }
  }
}
