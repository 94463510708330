import React, { useState, useEffect } from 'react';
import { DragDropContext } from '@hello-pangea/dnd';
import Column from './UIManagement/Column';
import Sidebar from './UIManagement/Sidebar';

const initialColumns = {
  'todo': { id: 'todo', title: 'Waiting Execution', cards: [] },
  'doing': { id: 'doing', title: 'Running', cards: [] },
  'done': { id: 'done', title: 'Completed', cards: [] },
};

const UseCrewUI = ({ deployment, inputs }) => {
  const [columns, setColumns] = useState(initialColumns);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [selectedExecution, setSelectedExecution] = useState(null);
  const [activeCrewExecutions, setActiveCrewExecutions] = useState([]);
  const [previousCrewExecutions, setPreviousCrewExecutions] = useState([]);

  const fetchExecutions = async () => {
    try {
      // Fetch active executions
      const activeResponse = await fetch(`/crewai_plus/onboarding/active_crew_executions/${deployment.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
      });

      // Fetch previous executions
      const previousResponse = await fetch(`/crewai_plus/onboarding/previous_crew_executions/${deployment.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
      });

      if (!activeResponse.ok || !previousResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const activeData = await activeResponse.json();
      const previousData = await previousResponse.json();

      console.log(activeData);
      console.log(previousData);

      setActiveCrewExecutions(activeData);
      setPreviousCrewExecutions(previousData);
    } catch (error) {
      console.error('Error fetching executions:', error);
    }
  };

  // Initial fetch and setup polling
  useEffect(() => {
    fetchExecutions(); // Initial fetch

    const intervalId = setInterval(() => {
      fetchExecutions();
    }, 5000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [deployment.id]);

  useEffect(() => {
    setColumns({
      ...initialColumns,
      'doing': {
        ...initialColumns.doing,
        cards: activeCrewExecutions.map(execution => ({
          id: execution.id,
          content: formatInputsToTags(execution.inputs),
          started_at: execution.started_at
        }))
      },
      'done': {
        ...initialColumns.done,
        cards: previousCrewExecutions.map(execution => ({
          id: execution.id,
          content: formatInputsToTags(execution.inputs),
          started_at: execution.started_at
        }))
      }
    });
  }, [activeCrewExecutions, previousCrewExecutions]);

  useEffect(() => {
    // Save data to localStorage
    localStorage.setItem('trelloColumns', JSON.stringify(columns));
  }, [columns]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    // Only allow reordering in the 'todo' column
    if (source.droppableId !== 'todo' || destination.droppableId !== 'todo') return;

    const column = columns[source.droppableId];
    const newCards = Array.from(column.cards);
    const [reorderedCard] = newCards.splice(source.index, 1);
    newCards.splice(destination.index, 0, reorderedCard);

    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        cards: newCards,
      },
    });
  };

  const addCard = (columnId, inputs) => {
    const column = columns[columnId];
    const newCard = { id: Date.now().toString(), content: formatInputsToTags(inputs) };
    setColumns({
      ...columns,
      [columnId]: {
        ...column,
        cards: [...column.cards, newCard],
      },
    });
    setIsAddingCard(false);
  };

  const deleteCard = (columnId, cardId) => {
    const column = columns[columnId];
    const updatedCards = column.cards.filter(card => card.id !== cardId);
    setColumns({
      ...columns,
      [columnId]: {
        ...column,
        cards: updatedCards,
      },
    });
  };

  // Replace the formatInputs function with this new function
  const formatInputsToTags = (inputs) => {
    if (!inputs || Object.keys(inputs).length === 0) return '<div>No inputs</div>';
    return `<div class="flex flex-wrap gap-2">
      ${Object.entries(inputs)
        .map(([key, value]) => `
          <span class="px-3 py-2 rounded-md text-xs font-semibold bg-gray-100 text-black">${key.replace(/_/g, ' ')}:
              <span class="ml-1 px-2 py-1 rounded-sm text-xs font-semibold bg-primary-color-lightest text-primary-color-darkest">${value}</span>
          </span>
        `)
        .join('')}
    </div>`;
  };

  const fetchExecutionDetails = async (executionId) => {
    try {
      const response = await fetch(`/crewai_plus/management_ui/${deployment.id}/execution/${executionId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSelectedExecution(data);
    } catch (error) {
      console.error('Error fetching execution details:', error);
    }
  };

  const handleCardClick = (executionId) => {
    fetchExecutionDetails(executionId);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex h-screen">
        <div className="flex-1 flex space-x-4 overflow-x-auto p-1">
          {Object.values(columns).map((column) => (
            <Column
              key={column.id}
              column={column}
              deleteCard={deleteCard}
              addCard={addCard}
              isAddingCard={isAddingCard}
              setIsAddingCard={setIsAddingCard}
              isDraggable={column.id === 'todo'}
              availableInputs={inputs}
              deployment={deployment}
              onCardClick={handleCardClick}
            />
          ))}
        </div>
        <Sidebar
          execution={selectedExecution}
          onClose={() => setSelectedExecution(null)}
        />
      </div>
    </DragDropContext>
  );
};

export default UseCrewUI;
