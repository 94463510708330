// CSS imports
import '@xyflow/react/dist/style.css';
import "choices.js/public/assets/styles/choices.min.css"
import '../assets/stylesheets/custom_nodes.css';

// Rails and Stimulus imports
import * as Turbo from "@hotwired/turbo"
import "./controllers"

// React and related imports
import React from 'react'
import { createRoot } from 'react-dom/client'
import UiStudioChat from './components/UiStudioChat'
import ManagementUI from './components/ManagementUI'
import UseCrewUI from './components/UseCrewUI'
import UICrewNodes from './components/UICrew/UICrewNodes'

// Third-party library imports
import 'flowbite'
import "chartkick"
import Chart from 'chart.js/auto'
import Choices from "choices.js"
import LocalTime from "local-time"

LocalTime.config.useFormat24 = true
LocalTime.start()

// Initialize Turbo
Turbo.start()

// Function to render React components
const renderReactComponent = (component, props, targetId) => {
  const targetElement = document.getElementById(targetId);
  if (targetElement) {
    if (!targetElement._reactRoot) {
      targetElement._reactRoot = createRoot(targetElement);
    }
    targetElement._reactRoot.render(React.createElement(component, props));
  }
}

// Make React components and functions available globally
window.React = React;
window.createRoot = createRoot;
window.renderReactComponent = renderReactComponent;
window.UiStudioChat = UiStudioChat;
window.ManagementUI = ManagementUI;
window.UseCrewUI = UseCrewUI;
window.UICrewNodes = UICrewNodes;
window.Turbo = Turbo;
window.Chart = Chart;
window.LocalTime = LocalTime;

// Function to initialize or reinitialize components
const initializeComponents = () => {
  window.renderReactComponent = renderReactComponent;
  window.UiStudioChat = UiStudioChat;
  window.UICrewNodes = UICrewNodes;

  // Initialize Flowbite
  if (typeof window.initFlowbite === 'function') {
    window.initFlowbite();
  }
};

// Event listeners for Turbo
document.addEventListener("turbo:load", initializeComponents);
document.addEventListener("turbo:render", initializeComponents);

// Initial call to initialize components
initializeComponents();
