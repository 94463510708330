import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["statusContainer", "connectButton"]

  connect() {
    this.checkingInterval = null
    this.checkAttempts = 0
    this.maxAttempts = 60 // 5 minutes (with 5s interval)
  }

  disconnect() {
    if (this.checkingInterval) {
      clearInterval(this.checkingInterval)
    }
  }

  startConnection() {
    this.updateStatus('connecting')
    this.startPolling()
  }

  startPolling() {
    this.checkingInterval = setInterval(() => {
      this.checkInstallationStatus()
    }, 5000) // Check every 5 seconds
  }

  async checkInstallationStatus() {
    this.checkAttempts++

    if (this.checkAttempts > this.maxAttempts) {
      clearInterval(this.checkingInterval)
      this.updateStatus('timeout')
      return
    }

    try {
      const response = await fetch('/crewai_plus/organizations/check_github_installation')
      const data = await response.json()

      if (data.installed) {
        clearInterval(this.checkingInterval)
        this.updateStatus('success')
        // Wait a moment to show success state before redirecting
        setTimeout(() => {
          window.location.href = '/crewai_plus/onboarding/github/select_repository'
        }, 1500)
      }
    } catch (error) {
      console.error('Error checking installation:', error)
    }
  }

  updateStatus(status) {
    const container = this.statusContainerTarget

    switch(status) {
      case 'connecting':
        container.innerHTML = this.connectingHTML
        break
      case 'success':
        container.innerHTML = this.successHTML
        break
      case 'timeout':
        container.innerHTML = this.timeoutHTML
        break
    }
  }

  get connectingHTML() {
    return `
      <div class="flex items-center gap-2 text-blue-600">
        <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <span>Waiting for GitHub connection... (You can close the popup window after installation)</span>
      </div>
    `
  }

  get successHTML() {
    return `
      <div class="flex items-center gap-2 text-green-600">
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
        </svg>
        <span>GitHub successfully connected! Redirecting...</span>
      </div>
    `
  }

  get timeoutHTML() {
    return `
      <div class="flex items-center gap-2 text-red-600">
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
        <span>Connection taking longer than expected. Please refresh the page if you've completed the GitHub installation.</span>
      </div>
    `
  }
}
