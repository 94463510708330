import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    deploymentId: Number,
    interval: Number
  }
  static targets = ["container"]

  connect() {
    this.checkStatus()
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  async checkStatus() {
    const response = await fetch(
      `/crewai_plus/deployments/${this.deploymentIdValue}/check_provision_status.json`,
      {
        headers: {
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
          'Accept': 'application/json'
        },
        credentials: 'same-origin'
      })
    const data = await response.json()
    const state = data.state?.toLowerCase()

    if (state === "successful") {
      this.containerTargets.forEach(container => {
        container.dispatchEvent(new CustomEvent('deploymentOnline'))
      })
    } else if (state === "in progress") {
      this.containerTargets.forEach(container => {
        container.dispatchEvent(new CustomEvent('deploymentInProgress'))
      })
    } else {
      this.stopAllRefreshes()
      return
    }

    this.timeout = setTimeout(() => {
      this.checkStatus()
    }, this.intervalValue || 10000)
  }

  stopAllRefreshes() {
    this.containerTargets.forEach(container => {
      container.dispatchEvent(new CustomEvent('stopRefresh'))
    })
  }
}
