// app/javascript/controllers/knowledge_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form",
    "uploadButton", 
    "previewContainer", 
    "fileList",
    "processingIndicator",
    "expandIcon",
    "fileInputLabel"
  ]


  handleFileSelect(event) {
    const files = event.target.files
    if (files.length > 0) {
      this.previewContainerTarget.classList.remove("hidden")
      this.uploadButtonTarget.classList.remove("hidden")
      
      const fileNames = Array.from(files).map(file => file.name).join(", ")
      this.fileListTarget.textContent = `Selected files: \n ${fileNames}`
    }
  }

  handleSubmit(event) {
    event.preventDefault()   
    this.processingIndicatorTarget.classList.remove("hidden")
    this.uploadButtonTarget.disabled = true
    
    const form = event.target
    
    Turbo.navigator.submitForm(form)
    form.reset()
    this.previewContainerTarget.classList.add("hidden")
    this.processingIndicatorTarget.classList.add("hidden")
    this.uploadButtonTarget.classList.add("hidden")
    this.uploadButtonTarget.disabled = false
    
    const agentId = form.dataset.agentId
    const expandedRow = document.querySelector(`[data-expanded-row="agent_${agentId}"]`)
    if (expandedRow && expandedRow.classList.contains("hidden")) {
      expandedRow.classList.remove("hidden")
      expandedRow.classList.add("block")
    }
  }

  toggleExpand(event) {
    const rowId = event.currentTarget.dataset.knowledgeRowIdParam;
    const expandedRow = document.querySelector(`[data-expanded-row="${rowId}"]`);
    const icon = event.currentTarget.querySelector('[data-knowledge-target="expandIcon"]');

    if (expandedRow.classList.contains('hidden')) {
      expandedRow.classList.remove('hidden');
      icon.classList.add('rotate-180');
    } else {
      expandedRow.classList.add('hidden');
      icon.classList.remove('rotate-180');
    }
  }
}
