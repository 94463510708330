import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "hiddenField", "tagContainer"]


  connect() {
    // This will store all of the current tags (emails)
    this.tags = []
  }

  // Triggered by keypress->tags#checkForDelimiter
  checkForDelimiter(event) {
    if (event.key === "," || event.key === "Enter") {
      event.preventDefault()
      this.addTag()
    }
  }

  addTag() {
    const rawValue = this.inputTarget.value.trim()
    if (rawValue === "") return

    // Split out any commas that might have been pasted in
    const newTags = rawValue.split(",").map(tag => tag.trim()).filter(tag => tag !== "")
    newTags.forEach(tag => {
      if (!this.tags.includes(tag)) {
        this.tags.push(tag)
        this.renderTag(tag)
      }
    })

    // Clear input
    this.inputTarget.value = ""

    // Update hidden field with the new list of tags
    this.hiddenFieldTarget.value = this.tags.join(",")
  }

  removeTag(event) {
    const tagToRemove = event.currentTarget.dataset.value
    this.tags = this.tags.filter(tag => tag !== tagToRemove)
    this.hiddenFieldTarget.value = this.tags.join(",")

    // Remove tag element from the DOM
    event.currentTarget.parentElement.remove()
  }

  renderTag(tag) {
    const span = document.createElement("span")
    span.className = "inline-flex text-xs items-center py-1 px-2 m-1 bg-gray-100 rounded border border-gray-300"
    span.textContent = tag

    const removeBtn = document.createElement("button")
    removeBtn.type = "button"
    removeBtn.className = "ml-2 text-red-500 hover:text-red-700"
    removeBtn.textContent = "x"
    removeBtn.dataset.action = "click->tags#removeTag"
    removeBtn.dataset.value = tag

    span.appendChild(removeBtn)
    this.tagContainerTarget.appendChild(span)
  }
}