import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form",
    "providerSelect",
    "customProviderFields",
    "modelsContainer",
    "fetchAvailableModelsButton",
    "currentProvider",
    "environmentVariablesContainer",
    "fetchError",
    "openaiCompatibleInfo"
  ];

  async connect() {
    // Add form submit handler
    this.formTarget.addEventListener('submit', this.handleSubmit.bind(this));

    // Hide/show fetch models button based on current provider
    const provider = this.getSelectedProvider();
    const isCustomProvider = provider === "custom-openai-compatible";
    this.fetchAvailableModelsButtonTarget.classList.toggle("hidden", !isCustomProvider);

    // If we're in edit mode (has currentProvider), fetch models automatically
    if (this.hasCurrentProviderTarget) {
      await this.fetchAvailableModels(provider);
    }
  }

  handleSubmit(event) {
    // Check if any models are selected
    const selectedModels = this.formTarget.querySelectorAll('input[name="llm_connection[available_models][]"]:checked');

    if (selectedModels.length === 0) {
      event.preventDefault();
      alert("Please select at least one model before saving");
      return false;
    }
  }

  async providerChanged() {
    await this.checkCustomProvider();
  }

  async checkCustomProvider() {
    const provider = this.getSelectedProvider();
    const isCustomProvider = provider === "custom-openai-compatible";

    this.customProviderFieldsTarget.classList.toggle("hidden", !isCustomProvider);
    this.fetchAvailableModelsButtonTarget.classList.toggle("hidden", !isCustomProvider);
    this.openaiCompatibleInfoTarget.classList.toggle("hidden", !isCustomProvider);

    if (isCustomProvider) {
      this.clearModelsContainer();
      await this.addRequiredEnvVars(provider);
    } else {
      this.removeRequiredEnvVars();
      await this.fetchAvailableModels(provider);
    }
  }

  removeRequiredEnvVars() {
    const notice = this.environmentVariablesContainerTarget.querySelector('.text-amber-600');
    if (notice) notice.remove();

    // Remove required marking from env vars and delete empty required ones
    const rows = this.environmentVariablesContainerTarget.querySelectorAll('.nested-fields');
    rows.forEach(row => {
      if (row.classList.contains('required-env-var')) {
        const valueInput = row.querySelector('input[name*="[value]"]');
        const keyInput = row.querySelector('input[name*="[key]"]');

        // If it's empty, remove the whole row
        if (!valueInput?.value?.trim()) {
          // If there's a destroy input, use it (for existing records)
          const destroyInput = row.querySelector('input[name*="_destroy"]');
          if (destroyInput) {
            destroyInput.value = "1";
            row.style.display = "none";
          } else {
            // For new records, remove the element directly
            row.remove();
          }
        } else {
          // If it has a value, just remove the required marking
          row.classList.remove('required-env-var');
          keyInput?.removeAttribute('required');
          valueInput?.removeAttribute('required');
        }
      }
    });
  }

  async addRequiredEnvVars(provider) {
    try {
      const response = await fetch(`/crewai_plus/llm_connections/required_environment_variables?provider=${provider}`);
      if (!response.ok) throw new Error('Failed to fetch required environment variables');

      const { required_env_vars } = await response.json();
      if (!required_env_vars?.length) return;

      const container = this.environmentVariablesContainerTarget;

      // Add required notice
      const notice = document.createElement('div');
      notice.className = 'text-sm text-amber-600 mb-3';
      notice.innerHTML = `
        <div class="flex items-center">
          <svg class="h-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"/>
          </svg>
          The following environment variables are required: ${required_env_vars.join(", ")}
        </div>
      `;

      container.insertBefore(notice, container.firstChild);

      // Get all existing key inputs
      const existingKeys = new Set(
        Array.from(container.querySelectorAll('input[name*="[key]"]'))
          .map(input => input.value)
      );

      // Mark existing env vars as required or create new ones if missing
      required_env_vars.forEach(key => {
        // Only proceed if the key doesn't already exist
        if (!existingKeys.has(key)) {
          this.addEnvVarRow(key, true);
        } else {
          // If it exists, just mark it as required
          const input = container.querySelector(`input[name*="[key]"][value="${key}"]`);
          if (input) {
            const row = input.closest('.nested-fields');
            this.markAsRequired(row);
          }
        }
      });
    } catch (error) {
      console.error('Error fetching required environment variables:', error);
    }
  }

  markAsRequired(row) {
    row.classList.add('required-env-var');
    const keyInput = row.querySelector('input[name*="[key]"]');
    const valueInput = row.querySelector('input[name*="[value]"]');
    keyInput.setAttribute('required', '');
    valueInput.setAttribute('required', '');
  }

  addEnvVarRow(key, required = false) {
    // Find the nested environment variables controller
    const nestedController = this.application.getControllerForElementAndIdentifier(
      this.environmentVariablesContainerTarget,
      'nested-environment-variables'
    );

    // Create new field using the controller's createField method
    const field = nestedController.createField();
    nestedController.fieldsTarget.appendChild(field);

    // Set the key and value
    const keyInput = field.querySelector('input[name*="[key]"]');
    if (keyInput) {
      keyInput.value = key;
      // Dispatch input event to trigger any validations
      keyInput.dispatchEvent(new Event('input', { bubbles: true }));
    }

    if (required) {
      this.markAsRequired(field);
    }
  }

  async triggerFetchAvailableModels(event) {
    event.preventDefault();
    await this.fetchAvailableModels(this.getSelectedProvider());
  }

  getSelectedProvider() {
    if(this.hasCurrentProviderTarget) {
      return this.currentProviderTarget.value;
    }
    return this.providerSelectTarget.value;
  }

  async fetchAvailableModels(provider) {
    const button = this.fetchAvailableModelsButtonTarget;
    button.disabled = true;
    button.innerHTML = "Fetching...";

    // Clear previous error message
    this.fetchErrorTarget.classList.add('hidden');
    this.fetchErrorTarget.querySelector('p').textContent = '';

    try {
      const params = new URLSearchParams({ provider });

      if (provider === "custom-openai-compatible") {
        // Get values from environment variables
        const { required_env_vars } = await fetch(`/crewai_plus/llm_connections/required_environment_variables?provider=${provider}`)
          .then(res => res.json());

        const envInputs = {};
        const allKeyInputs = this.environmentVariablesContainerTarget.querySelectorAll('input[name*="[key]"]');

        allKeyInputs.forEach(keyInput => {
          const row = keyInput.closest('.nested-fields');
          const valueInput = row?.querySelector('input[name*="[value]"]');
          if (keyInput.value && valueInput) {
            envInputs[keyInput.value] = valueInput;
          }
        });

        const missingVars = required_env_vars.filter(key => !envInputs[key]?.value);
        if (missingVars.length > 0) {
          throw new Error(`Please fill in the following environment variables: ${missingVars.join(", ")}`);
        }

        params.append("base_uri", envInputs["OPENAI_API_BASE"].value);
        params.append("api_key", envInputs["OPENAI_API_KEY"].value);
      }

      const response = await fetch(`/crewai_plus/llm_connections/fetch_models?${params}`);
      const data = await response.json();

      if (response.ok) {
        this.renderModels(data.models);
        // Clear any existing error message on success
        this.fetchErrorTarget.classList.add('hidden');
      } else {
        throw new Error(data.error || "Failed to fetch models");
      }
    } catch (error) {
      console.error("Error fetching models:", error);
      // Show error message in the UI
      this.fetchErrorTarget.querySelector('p').textContent = error.message ||
        "Failed to fetch models. Please check your credentials and try again.";
      this.fetchErrorTarget.classList.remove('hidden');
    } finally {
      button.disabled = false;
      button.innerHTML = "Fetch Available Models";
    }
  }

  clearModelsContainer() {
    const container = this.modelsContainerTarget;
    container.innerHTML = '';
  }

  renderModels(models) {
    const container = this.modelsContainerTarget;
    container.innerHTML = '';

    const gridDiv = document.createElement('div');
    gridDiv.className = 'grid grid-cols-1 lg:grid-cols-4 gap-4';

    // Add a "Select All" checkbox
    const selectAllDiv = document.createElement('div');
    selectAllDiv.className = 'col-span-full mb-4';
    selectAllDiv.innerHTML = `
      <label class="inline-flex items-center">
        <input type="checkbox" class="form-checkbox h-4 w-4 text-primary-color rounded focus:ring-primary-color border-gray-400"
               data-action="change->llm-connections#toggleAllModels">
        <span class="ml-2 text-sm font-medium text-gray-600">Select All Models</span>
      </label>
    `;
    container.appendChild(selectAllDiv);

    Object.entries(models).forEach(([modelName, capabilities]) => {
      const modelDiv = document.createElement('div');
      modelDiv.dataset.modelName = modelName.toLowerCase();

      const innerDiv = document.createElement('div');
      innerDiv.className = 'flex items-center';

      const checkboxDiv = document.createElement('div');
      checkboxDiv.className = 'flex items-center min-w-0';

      const checkbox = document.createElement('input');
      checkbox.type = 'checkbox';
      checkbox.name = 'llm_connection[available_models][]';
      checkbox.value = modelName;
      checkbox.id = `available_models_${modelName.replace(/[^a-z0-9]/gi, '_')}`;
      checkbox.className = 'flex-shrink-0 mr-2 h-4 w-4 form-checkbox text-primary-color rounded focus:ring-primary-color border-gray-400';

      const label = document.createElement('label');
      label.htmlFor = checkbox.id;
      label.className = 'text-sm font-medium text-gray-600 truncate';
      label.title = modelName;
      label.textContent = modelName;

      checkboxDiv.appendChild(checkbox);
      checkboxDiv.appendChild(label);
      innerDiv.appendChild(checkboxDiv);

      if (capabilities.supports_crew_studio) {
        const capabilityIcon = this.createCapabilityIcon();
        innerDiv.appendChild(capabilityIcon);
      }

      modelDiv.appendChild(innerDiv);
      gridDiv.appendChild(modelDiv);

      // Add checked state for existing selections
      if (this.hasCurrentProviderTarget) {
        const existingModels = this.modelsContainerTarget.dataset.selectedModels;
        if (existingModels) {
          const selectedModels = JSON.parse(existingModels);
          checkbox.checked = selectedModels.includes(modelName);
        }
      }
    });

    container.appendChild(gridDiv);
    container.classList.remove('hidden');
  }

  createCapabilityIcon() {
    const iconWrapper = document.createElement('div');
    iconWrapper.className = 'group relative';

    const iconDiv = document.createElement('div');
    iconDiv.className = 'ml-1 rounded-full bg-primary-50';
    iconDiv.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-primary-600" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" />
      </svg>
    `;

    const tooltip = document.createElement('div');
    tooltip.className = 'invisible group-hover:visible absolute z-10 px-2 py-1 text-xs text-white bg-gray-900 rounded-md -top-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap';
    tooltip.textContent = 'Supports Crew Studio';

    iconWrapper.appendChild(iconDiv);
    iconWrapper.appendChild(tooltip);
    return iconWrapper;
  }

  toggleAllModels(event) {
    const isChecked = event.target.checked;
    const modelCheckboxes = this.modelsContainerTarget.querySelectorAll('input[name="llm_connection[available_models][]"]');
    modelCheckboxes.forEach(checkbox => {
      checkbox.checked = isChecked;
    });
  }
}
