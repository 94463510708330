import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["list"]

  filter(event) {
    const searchTerm = event.target.value.toLowerCase()
    const repos = this.listTarget.querySelectorAll("a")

    repos.forEach(repo => {
      const name = repo.querySelector("h3").textContent.toLowerCase()
      const fullName = repo.querySelector("p").textContent.toLowerCase()

      if (name.includes(searchTerm) || fullName.includes(searchTerm)) {
        repo.style.display = "block"
      } else {
        repo.style.display = "none"
      }
    })
  }
}
