import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    deploymentId: String,
    type: String
  }

  connect() {
    this.startPolling()
  }

  disconnect() {
    if (this.intervalId) clearInterval(this.intervalId)
  }

  startPolling() {
    this.refreshLogs()
    this.intervalId = setInterval(() => {
      this.refreshLogs()
    }, 5000)
  }

  async refreshLogs() {
    try {
      const response = await fetch(this.urlValue)
      const html = await response.text()
      this.element.innerHTML = html

      // Auto-scroll if enabled
      const autoScrollEnabled = document.querySelector('[data-onboarding-deployment-logs-target="autoScrollToggle"]')?.checked
      if (autoScrollEnabled) {
        this.element.scrollTop = this.element.scrollHeight
      }
    } catch (error) {
      console.error("Error refreshing logs:", error)
    }
  }
}
