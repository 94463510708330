import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["loadingIcon"]

  connect() {
    console.log("Onboarding controller connected!")
  }

  async navigateToGithub(event) {
    console.log("Navigating to Github...")
    event.preventDefault()
    this.startLoading(event.currentTarget)
    window.location.href = "/crewai_plus/onboarding/github"
  }

  async navigateToTemplates(event) {
    event.preventDefault()
    this.startLoading(event.currentTarget)
    window.location.href = "/crewai_plus/onboarding/templates"
  }

  async navigateToStudio(event) {
    event.preventDefault()
    this.startLoading(event.currentTarget)
    window.location.href = "/crewai_plus/onboarding/studio"
  }

  startLoading(element) {
    // Replace the arrow icon with a loading spinner
    const arrowIcon = element.querySelector('svg:last-child')
    if (arrowIcon) {
      arrowIcon.innerHTML = `
        <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      `
    }

    // Disable the card to prevent multiple clicks
    element.style.pointerEvents = 'none'
    element.classList.add('opacity-75')
  }
}
