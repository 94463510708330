import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["connection", "model", "errorMessage"]

  connect() {
    this.updateModelSelectState()
  }

  updateModelSelectState() {
    const connectionValue = this.connectionTarget.value
    this.modelTarget.disabled = !connectionValue
    this.modelTarget.required = !!connectionValue
    this.updateErrorMessage()
  }

  modelChanged = () => {
    this.updateErrorMessage()
  }

  async connectionChanged() {
    const connectionId = this.connectionTarget.value

    if (!connectionId) {
      this.modelTarget.innerHTML = '<option value="">Select Model (Required)</option>'
      this.modelTarget.disabled = true
      this.modelTarget.required = false
      this.updateErrorMessage()
      return
    }

    try {
      const response = await fetch(`/crewai_plus/settings/defaults/available_models?llm_connection_id=${connectionId}`)
      const models = await response.json()

      this.modelTarget.innerHTML = '<option value="">Select Model (Required)</option>'
      models.forEach(model => {
        const option = document.createElement('option')
        option.value = model
        option.textContent = model
        this.modelTarget.appendChild(option)
      })

      this.modelTarget.disabled = false
      this.modelTarget.required = true
      this.updateErrorMessage()
    } catch (error) {
      console.error("Error fetching models:", error)
    }
  }

  updateErrorMessage() {
    if (!this.hasErrorMessageTarget) return

    const modelValue = this.modelTarget.value
    this.errorMessageTarget.classList.toggle('hidden', !!modelValue)
    this.modelTarget.classList.toggle('border-red-300', !modelValue)
    this.modelTarget.classList.toggle('border-gray-300', !!modelValue)
  }
}
