import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = {
    data: Array
  }

  connect() {
    this.renderChart()
  }

  renderChart() {
    const options = {
      series: [{
        name: 'Users',
        data: this.dataValue.map(item => item.count)
      }],
      chart: {
        type: 'area',
        height: 250,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      xaxis: {
        type: 'datetime',
        categories: this.dataValue.map(item => item.date),
        labels: {
          format: 'MMM yyyy'
        }
      },
      yaxis: {
        title: {
          text: 'Number of Users'
        },
        labels: {
          formatter: function(val) {
            return Math.round(val)
          }
        }
      },
      colors: ['#EB6658'],
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.3,
          stops: [0, 90, 100]
        }
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        }
      }
    }

    const chart = new ApexCharts(this.element, options)
    chart.render()
  }
} 