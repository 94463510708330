import { Controller } from "@hotwired/stimulus"
import ApexCharts from "apexcharts"

export default class extends Controller {
  static values = {
    data: Array
  }

  connect() {
    this.initChart()
  }

  initChart() {
    const generateColors = (count) => {
      return Array.from({ length: count }, (_, i) => {
        const hue = (i * (360 / count)) % 360;
        return `hsl(${hue}, 65%, 55%)`; // Adjusted saturation and lightness for better visibility
      });
    };

    const options = {
      series: [{
        name: 'Executions',
        data: this.dataValue.map(org => org.executions)
      }],
      chart: {
        type: 'bar',
        height: 375,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 4,
          dataLabels: {
            position: 'top',
          },
          distributed: true
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return val.toLocaleString()
        },
        style: {
          colors: ['#fff'],
          fontSize: '12px',
          fontWeight: 'bold'
        },
        background: {
          enabled: true,
          foreColor: '#000',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
        }
      },
      xaxis: {
        categories: this.dataValue.map(org => org.name),
        title: {
          text: 'Number of Executions'
        }
      },
      yaxis: {
        title: {
          text: 'Organizations'
        }
      },
      colors: generateColors(this.dataValue.length),
      title: {
        text: 'Top Organizations by Kickoff Crew Executions (last week)',
        align: 'center',
        style: {
          fontSize: '18px'
        }
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val.toLocaleString() + ' executions'
          }
        }
      },
      legend: {
        show: false
      }
    }

    const chart = new ApexCharts(this.element, options)
    chart.render()
  }
} 